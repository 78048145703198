<template>
    <TemplateContentPage
        class="page-error"
        :entry="entry"
    >
        <template #hero>
            <BaseHero
                v-if="heroImage"
                :image="heroImage"
            >
                <template
                    v-if="title"
                    #title
                >
                    {{ title }}
                </template>
            </BaseHero>
        </template>

        <template #default>
            <BaseSection
                width="content-sm"
                class="section--rich-text"
                color-theme="dark"
            >
                <div v-html="description" /><!-- eslint-disable-line -->
            </BaseSection>
        </template>
    </TemplateContentPage>
</template>

<script>
import imageUrlLg from '~/assets/img/placeholders/placeholder@5x.jpg';

export default {
    props: {
        statusCode: {
            type: Number,
            default: 0
        }
    },

    computed: {
        heroImage() {
            return {
                url: imageUrlLg,
                url_hero_lg: imageUrlLg,
                url_hero_md: imageUrlLg,
                url_hero_sm: imageUrlLg,
                url_hero_tiny: imageUrlLg,
                width: 0,
                height: 0
            };
        },

        entry() {
            return {};
        },

        title() {
            if (this.statusCode === 404) {
                return 'Pagina niet gevonden';
            } else if (this.statusCode === 500) {
                return 'Er ging iets mis';
            }
            return '';
        },

        description() {
            if (this.statusCode === 404) {
                return '<p>Deze pagina bestaat niet meer.</p><p>Op zoek naar een programma? Bekijk het <a href="/">programmaoverzicht</a>.</p>';
            } else if (this.statusCode === 500) {
                return '<p>Er ging iets mis bij het laden van deze pagina. We doen ons best dit zo snel mogelijk te verhelpen.</p><p>Op zoek naar een programma? Bekijk het <a href="/">programmaoverzicht</a>.</p>';
            }
            return '';
        }
    }
};
</script>

<style lang="less" src="./PageError.less"></style>
